import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    transparent: "transparent",
    black: "var(--shadow)",
    white: "var(--highlight)",

    primaryLightest: "var(--primary-lightest)",
    primaryLighter: "var(--primary-lighter)",
    primary: "var(--primary)",
    primaryDarker: "var(--primary-darker)",
    primaryDarkest: "var(--primary-darkest)",

    secondary: "var(--secondary)",
    secondaryLightest: "var(--secondary-lightest)",
    secondaryLighter: "var(--secondary-lighter)",
    secondaryDarker: "var(--secondary-darker)",
    secondaryDarkest: "var(--secondary-darkest)",

    alternate: "var(--alternate)",
    alternateLightest: "var(--alternate-lightest)",
    alternateLighter: "var(--alternate-lighter)",
    alternateDarker: "var(--alternate-darker)",
    alternateDarkest: "var(--alternate-darkest)",

    primaryLightestA: "var(--primary-lightest-a)",
    primaryLighterA: "var(--primary-lighter-a)",
    primaryA: "var(--primary-a)",
    primaryDarkerA: "var(--primary-darker-a)",
    primaryDarkestA: "var(--primary-darkest-a)",

    secondaryA: "var(--secondary-a)",
    secondaryLightestA: "var(--secondary-lightest-a)",
    secondaryLighterA: "var(--secondary-lighter-a)",
    secondaryDarkerA: "var(--secondary-darker-a)",
    secondaryDarkestA: "var(--secondary-darkest-a)",

    alternateA: "var(--alternate-a)",
    alternateLightestA: "var(--alternate-lightest-a)",
    alternateLighterA: "var(--alternate-lighter-a)",
    alternateDarkerA: "var(--alternate-darker-a)",
    alternateDarkestA: "var(--alternate-darkest-a)",

    highlight: "var(--highlight)",
    shadow: "var(--shadow)",
    inactive: "var(--inactive)",
  },
  fonts: {
    heading: "museo-sans-rounded",
    body: "museo-sans-rounded",
  },
  components: {
    Link: {
      baseStyle: {
        color: "primary",
      },
    },

    Button: {
      baseStyle: {
        color: "primary",
      },
    },
  },
});
export default theme;
