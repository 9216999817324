import { entries, flow, map } from "lodash/fp";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useState } from "react";

import logoBlue from "shared/assets/images/Lokalyze_Logo_SecondaryLogo_Blue.png";
import logoWhite from "shared/assets/images/Lokalyze_Logo_SecondaryLogo_White.png";

import Hamburger from "./Hamburger";

export const LOGO_ASPECT_RATIO = 4243 / 1198;
export const LOGO_HEIGHT = 50;
export const LOGO_WIDTH = LOGO_ASPECT_RATIO * LOGO_HEIGHT;

type NavItem = {
  name: string;
  path: string;
  exact?: boolean;
  extra?: string;
};

const NavListItem = ({ className = "", children }: { className: string; children: ReactNode }) => (
  <li className={"self-center br2 pa2 tc f4 b" + className}>{children}</li>
);

const getPathComparator = (exact: boolean) => (a: string, b: string) =>
  exact ? a === b : a.startsWith(b);

const hoverClasses = (leftMargin: boolean) =>
  leftMargin ? ["hover-bg-black-10", "ml-auto-l"] : ["hover-bg-black-10"];

const selectedClass = (selected: boolean) => (selected ? "bg-black-10" : "");

const hydrateNavItemClasses = (current: string) => ([i, v]: [string, NavItem]) => ({
  ...v,
  extra: [
    v.extra,
    ...hoverClasses(i === "0"),
    selectedClass(getPathComparator(v.exact!)(current, v.path)),
  ].join(" "),
});

const navItemToJSX = (invert: boolean) => (v: NavItem) => (
  <NavListItem key={v.path} className={v.extra!}>
    <Link href={v.path}>
      <a className="link">{v.name}</a>
    </Link>
    <style jsx>{`
      a {
        color: black;
      }
      @media screen and (min-width: 960px) {
        a {
          color: ${invert ? "black" : "white"};
        }
      }
    `}</style>
  </NavListItem>
);

const buildNav = (current: string, invert: boolean) =>
  flow(
    // NavItem to [index, NavItem]
    entries,
    // add classes for hover and current route
    map(hydrateNavItemClasses(current)),
    // convert to JSX
    map(navItemToJSX(invert))
  );

type Props = {
  absolute: boolean;
  items: NavItem[];
  bg: string;
  whiteLogo: boolean;
};

type NavListProps = {
  items: NavItem[];
  route: string;
  show: boolean;
  auth: boolean;
  bg: string;
  invert: boolean;
};

const NavList = ({
  items,
  route,
  show,
  auth,
  bg = "rgba( 255, 255, 255, .5 )",
  invert,
}: NavListProps) => (
  <ul
    className={[
      // Generic
      show ? "flex-l" : "flex-l dn",
      "list",
      "ma0",
      "pa3",
      "flex-grow-1",
      "w-auto",

      // Mobile
      "flex-column",
      "bg-white",
      "br2",
      "absolute",
      "black",

      // Desktop (-ns)
      "br4-l",
      "pv2-l",
      "ph4-l",
      "flex-row-l",
      "justify-between-l",
      "align-center-l",
      "relative-l",
      "white-l",
    ].join(" ")}
  >
    {buildNav(route, invert)(items)}
    {/* {auth
      ? navItemToJSX({
          name: "Dashboard",
          path: "/dashboard",
          extra: selectedClass(route === "/dashboard"),
        })
      : navItemToJSX({
          name: "Login",
          path: "/login",
          extra: selectedClass(route === "/login"),
        })} */}

    <style jsx>{`
      ul {
        filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
        gap: 1em;
      }
      @media screen and (min-width: 960px) {
        ul {
          transition: background-color 0.5s ease;
          background-color: ${bg};
        }
      }
      @media screen and (max-width: 960px) {
        ul {
          top: 5rem;
          right: 2rem;
        }
      }
    `}</style>
  </ul>
);

const Nav = ({ absolute, items, bg, whiteLogo }: Props) => {
  const [show, setShow] = useState(false);
  const { route } = useRouter();

  return (
    <>
      <nav
        role="main"
        className={[
          "flex",
          "items-start",
          "ph6-l",
          "ph3",
          "pv3",
          "justify-between",
          "z-999",
          absolute ? "absolute w-100" : "fixed w-100",
        ].join(" ")}
      >
        <div className="logo-container flex-shrink-0 pa2 br4">
          <Link href="/">
            <a className="link black">
              <span className="flex items-center">
                <Image
                  src={whiteLogo ? logoWhite : logoBlue}
                  width={LOGO_WIDTH}
                  height={LOGO_HEIGHT}
                  layout="fixed"
                  className="pa2"
                />
              </span>
            </a>
          </Link>
        </div>

        <div className="flex-shrink-0 pa2 flex flex-column">
          <div className="dn-l ml-auto">
            <Hamburger active={show} toggle={setShow} />
          </div>

          <NavList
            items={items}
            route={route}
            show={show}
            auth={false}
            bg={bg}
            invert={!whiteLogo}
          />
        </div>
      </nav>

      <style jsx>{`
        nav {
          min-height: 4em;
        }
        .logo-container {
          filter: drop-shadow(8px 8px 10px hsla(90, 10%, 10%, 20%));
          transition: background-color 0.5s ease;
          background-color: ${bg};
        }
      `}</style>
    </>
  );
};

export default Nav;
