import { NextSeo } from "next-seo";
import { ReactNode } from "react";

import Footer from "../Footer";
import Nav from "../Nav";

export type LayoutProps = {
  bare?: boolean; // when true, no <main> or padding is applied
  absoluteNav?: boolean; // when true, nav is position: absolute
  footer?: boolean; // false to hide footer
  title?: string;
  description?: string;
  navHighlight?: string; // nav background
  navLogoWhite?: boolean;
  children: ReactNode;
};

const Layout = ({
  absoluteNav = false,
  footer = true,
  title,
  description,
  navHighlight = "white",
  navLogoWhite = false,
  children,
}: LayoutProps) => {
  return (
    <>
      <NextSeo title={title} description={description} />

      <div id="container">
        <Nav
          absolute={absoluteNav}
          items={[
            { name: "Join Lokalyze", path: "/join" },
            { name: "For Retailers", path: "/retail" },
          ]}
          bg={navHighlight}
          whiteLogo={navLogoWhite}
        />

        {children}
      </div>

      {footer ? <Footer /> : null}

      <style jsx>{`
        #container {
          flex: 1 0 auto;
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </>
  );
};

export default Layout;
