import { map } from "lodash/fp";
import Link from "next/link";

type Item = [string, string];

const list = (heading: string, items: Array<Item>) => (
  <div className="ph3 flex flex-column f5">
    <h4 className="ma0 b">{heading}</h4>
    <ul className="list pa0">
      {map(([path, name]) => (
        <li key={path}>
          <Link href={path}>
            <a className="link black-60 hover-black underline-hover" target="_blank">
              {name}
            </a>
          </Link>
        </li>
      ))(items)}
    </ul>

    <style jsx>{`
      a:visited {
        color: black;
      }
    `}</style>
  </div>
);

const Footer = () => (
  <footer className="bg-black-10 flex flex-column">
    <div className="black-80 pa4-ns pa4 flex flex-column flex-row-ns justify-around center measure-wide">
      {list("Product", [
        // ["/about", "About"],
        ["/join", "Download"],
        ["/retail", "Retail"],
        // ["/privacy", "Privacy"],
      ])}

      {list("Social", [
        ["https://facebook.com/golokalyze", "Facebook"],
        ["https://twitter.com/golokalyze", "Twitter"],
        ["https://www.linkedin.com/company/golokalyze", "LinkedIn"],
      ])}
    </div>

    <div className="measure center tc f6 pa2">
      <p>Lokalyze Group Limited </p>
    </div>

    <style jsx>{`
      footer {
        scroll-snap-align: end;
      }
    `}</style>
  </footer>
);

export default Footer;
