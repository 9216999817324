import { ChakraProvider } from "@chakra-ui/react";
import { NextPage } from "next";
import { DefaultSeo } from "next-seo";
import Head from "next/head";

import Layout from "src/components/layouts/Default";

import "shared/styles/base.css";
import theme from "shared/styles/chakra";

import "../styles.css";
import "tachyons/css/tachyons.css";

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page: NextPage) => <Layout>{page}</Layout>);

  return (
    <>
      <DefaultSeo
        title="Home"
        titleTemplate="%s | Lokalyze"
        description="A simple browser extension that encourages you to shop locally."
        canonical="https://www.joinlokalyze.com/"
        openGraph={{
          images: [
            {
              url: "/Logo/RGB/PNG/props_01.png",
              width: 1198,
              height: 1198,
              alt:
                "A man using the Lokalyze browser extension to find local alternatives to purchase a product he searched on ebay.",
            },
            {
              url: "/Logo/RGB/PNG/Lokalyze_Logo_Master_Icon_OnBlue.png",
              width: 1198,
              height: 1198,
              alt:
                "The Lokalyze logo on a blue background, a yellow shopping bag inside a green ring with a map pin shape within the negative space.",
            },
            {
              url: "/Logo/RGB/PNG/Lokalyze_Logo_Master_Icon.png",
              width: 1198,
              height: 1198,
              alt:
                "The Lokalyze logo, a yellow shopping bag inside a green ring with a map pin shape within the negative space.",
            },
          ],
        }}
        twitter={{
          handle: "@golokalyze",
          site: "@golokalyze",
          cardType: "summary_large_image",
        }}
      />

      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#186ffb" />

        <meta name="msapplication-TileColor" content="#186ffb" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta name="theme-color" content="#186ffb" />

        <link rel="stylesheet" href="https://use.typekit.net/jbq6wyt.css" />
      </Head>

      <ChakraProvider theme={theme}>
        {/* --- */}

        {getLayout(<Component {...pageProps} />)}

        {/* --- */}
      </ChakraProvider>

      <style jsx global>{`
        html,
        body,
        #__next {
          height: 100%;
          scroll-snap-type: y proximity;
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @media {
            html,
            body,
            #__next {
              scroll-snap-type: none;
            }
          }
        }
        #__next {
          display: flex;
          flex-direction: column;
          scroll-snap-align: start;
        }
      `}</style>
    </>
  );
}

export default MyApp;
